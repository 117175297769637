<template >
  <div id="pages">
    <div class="title-name">产地环境检测系统</div>
    <div class="center-box">
      <div class="connent-box">
        <div class="input-box">
          <el-input class="input-name" v-model="username" placeholder="请输入企业名称或手机号"></el-input>
        </div>
        <div class="input-box2">
          <el-input class="input-name" type="password" v-model="userPwd" placeholder="请输入密码"></el-input>
        </div>
        <el-button @click="login">登录</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      username: '',
      userPwd: ''
    }
  }, mounted() {
  },
  methods: {
    login() {
      let params = {"mobile": this.username, "password": this.userPwd}
      this.$api.post('login', params, res => {
        // console.log('登录成功***')
        //
        // console.log('登录成功***',res)
        // console.log('登录成功***',res.user)
        // console.log(JSON.stringify(res.user))

        if (res.code === 0&&res.user.companyType === '4') {
          localStorage.setItem('token', res.token)
          localStorage.setItem('changeNavHead','')
          localStorage.setItem('storageUser', JSON.stringify(res.user))
          localStorage.setItem('nickName', res.user.nickName)
          this.$store.dispatch("storageUser", res.user)
          localStorage.setItem('permissionList', JSON.stringify(res.user.urlList))
          // this.$store.dispatch('storageUser', res.user)
          // if(this.$store.state.user.userName=='qihexian'){
            this.$router.replace('/qiheIndex')
            localStorage.setItem('changeNavHead','jidi')
          this.$bas.$emit("suiji",'/qiheIndex')
          // }
          // else {
          //   this.$router.push('/')
          // }
        }else if (res.user.companyType !='4'){
           this.$message('您没有权限登录')
        }
      })
    }
  }
}
</script>

<style >
#pages {
  background-image: url("../../assets/login_bg.png");
  height: 98.3vh;
  text-align: center;
  width: auto;
}

.center-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connent-box {
  width: 620px;
  /*min-width: 620px;*/
  height: 460px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 80px;
}

#pages .el-input__inner {
  width: 460px;
  height: 60px;
}

#pages .el-button {
  width: 450px;
  height: 62px;
  margin-top: 60px;
  background: #009400;
  color: #FFFFFF;
  font-size: 26px;
}

.input-box {
  width: 460px;
  height: 60px;
  padding-top: 100px;
  margin-left: 85px;
}

.input-box2 {
  width: 460px;
  height: 60px;
  padding-top: 40px;
  margin-left: 85px;
}

.title-name {
  font-size: 58px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #009400;
  padding-top: 130px;
}
</style>
